<template>
  <div class="container">
    <el-row class="row">
      <el-col :span="6">
        <el-select v-model="apiList" placeholder="input api" multiple class="api-selector">
          <el-option v-for="item in apiOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="10"> <TimeZone @change="dataChange" @changeDateZone="selectDateZone"></TimeZone></el-col>
      <el-col :span="4">
        <el-select
          class="selector"
          v-model="appName"
          filterable
          placeholder="Please choose app protocol"
          @change="appChange"
        >
          <el-option v-for="item in appOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-input class="ip-input" v-model="ipAddress">
          <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-collapse v-model="activeNames">
      <el-collapse-item v-for="(api, index) in Object.keys(apiMap)" :key="index" :name="index"
        ><template slot="title">
          <div class="api-name">{{ apiOptionsMap[api] }}</div>
        </template>

        <PaginationTable :id="api" :originData.sync="apiMap[api]" :showRule="showRule"></PaginationTable>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import NumberCol from '@/components/NumberCol'
import TimeZone from '@/components/TimeZone'
import { getFlowDetailTable, getAppInfoById } from '@/api/FlowApi'
import { getAppList } from '@/api/AppApi.js'
import { formatData } from '@/utils/dateHandler.js'
// import PaginationTable from '@/components/PaginationTable'
export default {
  name: 'Remote',
  components: {
    TimeZone,
    // Chart,
    // PaginationTable2: PaginationTable,
  },
  created() {},
  data() {
    return {
      appName: '',
      ipVersion: 'nf',
      ipAddress: '183.173.24.102',
      dateArray: [],
      appOption: [],
      results: [],
      apiMap: {},
      DataZone: parseInt(new Date().getTimezoneOffset() / 60),
      loading: false,
      apiList: ['/api', '/bdapi', '/learnapi'],
      activeNames: [],
      apiOptionsMap: {},
      apiOptions: [
        {
          value: '/api',
          label: 'CERNET',
        },
        {
          value: '/bdapi',
          label: 'BDREN',
        },
        {
          value: '/learnapi',
          label: 'LEARN',
        },
      ],
      showRule: [
        {
          prop: 'App_Protocol',
          label: 'App',
          width: '120px',
        },
        // 隐藏protocol
        // {
        //   prop: 'Master_Protocol',
        //   label: 'Protocol',
        //   width: '120px',
        // },
        {
          prop: 'Src_IP',
          label: 'Client IP',
          width: '120px',
        },
        {
          prop: 'Dst_IP',
          label: 'Server IP',
          width: '120px',
        },
        {
          prop: 'First_Seen',
          label: 'First Seen',
          width: '180px',
          sortable: 'custom',
          compare: (a, b) => new Date(a.First_Seen) - new Date(b.First_Seen),
        },
        {
          prop: 'Last_Seen',
          label: 'Last Seen',
          width: '180px',
          sortable: 'custom',
          compare: (a, b) => new Date(a['Last Seen']) - new Date(b['Last Seen']),
        },
        {
          prop: 'Dst_Port',
          label: 'Server Port',
          width: '100px',
          keepOriginal: true,
        },
        {
          prop: 'Src_Port',
          label: 'Client Port',
          width: '100px',
          keepOriginal: true,
        },
        {
          prop: 'Src_to_Dst_pps',
          label: 'Client to Server pps',
          // fixed: 'right',
          sortable: 'custom',
          compare: (a, b) => a.Src_to_Dst_pps - b.Src_to_Dst_pps,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_pps
          },
        },
        {
          prop: 'Src_to_Dst_bps',
          label: 'Client to Server bps',
          // fixed: 'right',
          sortable: 'custom',
          compare: (a, b) => a.Src_to_Dst_bps - b.Src_to_Dst_bps,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_bps
          },
        },
        {
          prop: 'Dst_to_Src_pps',
          label: 'Server to Client pps',
          // fixed: 'right',
          sortable: 'custom',
          compare: (a, b) => a.Dst_to_Src_pps - b.Dst_to_Src_pps,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_pps
          },
        },
        {
          prop: 'Dst_to_Src_bps',
          label: 'Server to Client bps',
          // fixed: 'right',
          sortable: 'custom',
          compare: (a, b) => a.Dst_to_Src_bps - b.Dst_to_Src_bps,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_bps
          },
        },
      ],
    }
  },
  mounted() {
    const apiOptionsMap = {}
    this.apiOptions.forEach(item => {
      apiOptionsMap[item.value] = item.label
    })
    this.apiOptionsMap = apiOptionsMap

    this.$nextTick(() => {
      this.doSearch()
    })
  },
  props: {},
  methods: {
    async getAppList() {
      const appList = await getAppList(this.ipVersion, ...this.dateArray, this.apiList[0])
      if (!this.$route.query.appName) {
        this.appName = 'HTTPS'
      }
      this.appOption = appList.map((app, index) => {
        return {
          value: index,
          label: app,
        }
      })
    },
    async searchByApi(api) {
      // const apiMap = Object.assign({}, this.apiMap)
      const result = await getFlowDetailTable(this.ipVersion, ...this.dateArray, this.appName, this.ipAddress, api)

      this.$set(
        this.apiMap,
        api,
        result.flow_table.map(item => {
          const date = new Date(item['First_Seen'] - this.DataZone * 60 * 60 * 1000)
          // console.debug(date)
          item['First_Seen'] = formatData(date)
          // console.debug(item['First_Seen'])
          if (!item['Last_Seen']) {
            item['Last_Seen'] = item['First_Seen']
          } else {
            const lastDate = new Date(item['Last_Seen'] - this.DataZone * 60 * 60 * 1000)
            item['Last_Seen'] = formatData(lastDate)
          }
          if (item['Dst_Port'] === -1) {
            item['Dst_Port'] = 'N/A'
          }

          if (item['Src_Port'] === -1) {
            item['Src_Port'] = 'N/A'
          }
          return item
        })
      )

      //  = apiMap
    },
    doSearch() {
      this.apiList.forEach(api => {
        this.searchByApi(api)
      })
    },
    dataChange(dateArray, DataZone) {
      this.dateArray = dateArray
      this.DataZone = DataZone
      this.getAppList()
    },
    selectDateZone(newDateZone, dateArray) {
      this.DataZone = newDateZone
      this.dateArray = dateArray
      this.getAppList()
    },
    async appChange(appIndex) {
      this.appName = this.appOption[appIndex].label
    },
    rowClick(row, column, event) {
      this.$router.push({
        path: `/flow_view/`,
        query: {
          appName: row.name,
          ip: row.ip,
          baseUrl: row.baseUrl,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  min-height: calc(100vh - 120px);
}
.api-selector,
.ip-input {
  width: 100%;
}
.api-name {
  background-color: #fff;
  border-radius: var(--border-radius-deg);
  font-size: var(--subTitle-font-size);
  text-align: left;
  color: #8b42a5;
  padding-left: 10px;
  width: 100%;
  // width: calc(100% - 20px);
  font-weight: bold;
}
.as-role-name {
  background-color: #d0b2db;
  color: #fff;
  width: 50%;
  text-align: center;
  margin: auto;
  border-radius: var(--border-radius-deg);
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  padding: 5px 0;
}

.selector {
  padding-right: 20px;
  width: calc(100% - 20px);
}
</style>
